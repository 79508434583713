.appnew {
  /* background-image: url("https://i.postimg.cc/NfTRm7Sn/background-Example.png"); */
  background-image: url("../../data/background.jpg");
  display: flex;
  align-items: center;
  /* gap: 20px; */
  height: 87%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: center;
  flex-direction: column;
  background-color: #f4f4f4;
  /* margin-bottom: -10vh; */
  /* overflow-y: hidden; */
  /* position: fixed; */
}

@media (max-width: 480px) {
  .appnew {
    /* background-image: url("https://i.postimg.cc/NfTRm7Sn/background-Example.png"); */
    background-image: url("../../data/169bg.png");
    display: flex;
    align-items: center;
    /* gap: 20px; */
    height: 85%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    flex-direction: column;
    background-color: #f4f4f4;
    /* margin-bottom: -10vh; */
    /* overflow-y: hidden; */
    /* position: fixed; */
  }
}

.login-form {
  background-color: white;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
  width: 600px;
  height: 400px;
}
