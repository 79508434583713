@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body,
html {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"), url(./fonts/Poppins-Black.ttf) format("truetype");
}
@tailwind base;
@tailwind components;
@tailwind utilities;
